import { FC, useState } from "react";
import {
  PuxLabel,
  PuxText,
  PuxSkeletonText,
  PuxToggle,
  PuxIcon,
  useBreakpoint,
} from "@phonero/pux-react";

import { useCurrentSubscriptionId, graphqlMutationToast, dataCy, useAppTranslations } from "./util";
import { approved } from "@phonero/pux-icons/icons";
import {
  ApiProductCategory,
  ApiProductState,
  Barring,
  BarringProduct,
  CostControl,
  CostControlProduct,
  DataRollover,
  MbnUserProduct,
  UnlimitedMinutes,
} from "@phonero/common-graphql/types";

import { Perhaps } from "./util/typeHelpers";
import { DeactivateServiceDocument } from "./queries/DeactivateService.generated";
import { AddBarringDocument } from "./queries/AddBarring.generated";
import { useMutation } from "@apollo/client";
import { useBreakpointBelowDesktop } from "./util/platform";
import { MobileInsuranceLinks } from "./MobileInsuranceLinks/MobileInsuranceLinks";

import css from "./ServiceToggle.module.scss";

type ServiceProps = Partial<
  | BarringProduct
  | DataRollover
  | UnlimitedMinutes
  | MbnUserProduct
  | CostControlProduct
  | CostControl
  | Barring
  | BarringProduct
>;

export const ServiceToggle: FC<ServiceProps> = (product) => {
  const { i18n, t } = useAppTranslations();
  const smMax = useBreakpoint("sm", { useMin: false, useMax: true });
  const compact = useBreakpointBelowDesktop();
  const withLabel = smMax;
  const { subscriptionId } = useCurrentSubscriptionId();
  const [activate, activateQuery] = useMutation(AddBarringDocument, {
    ...graphqlMutationToast(t, "barring"),
  });
  const [disable, disableQuery] = useMutation(DeactivateServiceDocument, {
    ...graphqlMutationToast(t, "deactivateService"),
  });
  const [showMore, setShowMore] = useState(false);
  const [checked, setChecked] = useState(
    product.state === ApiProductState.Active
  );
  const { __typename, name, price, isLocked, state } = product;

  if (!__typename) {
    return <PuxSkeletonText animated />;
  }

  const isDisabled =
    (state !== ApiProductState.Active && state !== ApiProductState.Available) ||
    activateQuery?.loading ||
    disableQuery?.loading;

  const toggleService = async ({ detail }) => {
    const productId = product?.productId?.toString();
    const id = product?.id?.toString();
    setChecked(detail.checked);
    if (
      state === ApiProductState.Available &&
      product.__typename === "BarringProduct" &&
      productId
    ) {
      if (!subscriptionId) {
        throw new Error("no subscriptionId (disable)");
      }
      activate({
        variables: { input: { subscriptionId, productId } },
        refetchQueries: ["SubscriptionServices"],
        awaitRefetchQueries: true,
      });
    }
    if (
      state === ApiProductState.Active &&
      product.__typename === "Barring" &&
      id
    ) {
      if (!subscriptionId) {
        throw new Error("no subscriptionId (disable)");
      }
      disable({
        variables: { input: { id } },
        refetchQueries: ["SubscriptionServices"],
        awaitRefetchQueries: true,
      });
    }
  };
  const asToggle = isLocked === false;
  const descriptionCutoffIndex = 140; // Magic number based on Figma examples
  const maxDescriptionLength = 160; // Magic number based on Figma examples
  const shouldTruncateDescription =
    product.description && product.description.length > maxDescriptionLength;
  const description =
    shouldTruncateDescription && !showMore
      ? `${product.description!.slice(0, descriptionCutoffIndex)}...`
      : product.description;

  const categoriesWithDescription = [
    ApiProductCategory.Unlimitedminutes,
    ApiProductCategory.Roamlikeathomeextensions,
    ApiProductCategory.Mobileinsurance,
    ApiProductCategory.Costcontrol,
  ];

  return (
    <>
      <PuxLabel
        wrap
        {...dataCy("toggle", { state: product.state, checked })}
        style={{ fontSize: "1.7rem" }}
      >
        {name}
        {!!description &&
          product.category &&
          categoriesWithDescription.indexOf(product.category) > -1 && (
            <div>
              <PuxText style={{ opacity: 0.6 }}>{description}</PuxText>
              {shouldTruncateDescription && (
                <button
                  className={css.linkButton}
                  onClick={() => setShowMore(!showMore)}
                >
                  {!showMore && "Vis mer"}
                  {showMore && "Vis mindre"}
                </button>
              )}
            </div>
          )}
        {product.category === ApiProductCategory.Mobileinsurance && (
          <MobileInsuranceLinks
            hasActiveProduct={true}
            productId={product?.productId || 0}
            styleType="button"
          />
        )}
      </PuxLabel>
      {!!price && <PuxText>{i18n.format(price, "price")}</PuxText>}
      {!asToggle && (
        <PuxText
          slot="end"
          style={{ fontWeight: 600 }}
          color={state === ApiProductState.Active ? "tertiary" : undefined}
        >
          <State state={product.state} compact={compact} />
        </PuxText>
      )}
      {asToggle && (
        <div
          slot="end"
          style={{
            display: "flex",
            alignItems: "center",
            verticalAlign: "center",
          }}
        >
          {withLabel && (
            <State state={product.state} compact={compact} actionable={true} />
          )}
          <PuxToggle
            style={{
              // Consider fixing the vertical alignment in pux.
              marginTop: -4,
            }}
            slot="end"
            onPuxChange={toggleService}
            checked={checked}
            disabled={isDisabled}
            color="tertiary"
          />
        </div>
      )}
    </>
  );
};

const stateIconMap: Partial<Record<ApiProductState, any>> = {
  [ApiProductState.Active]: approved,
};

function State({
  compact,
  state,
  actionable,
}: {
  actionable?: boolean;
  compact?: boolean;
  state: Perhaps<ApiProductState>;
}) {
  const { t } = useAppTranslations();
  if (!state) {
    return null;
  }
  const s = stateIconMap[state];
  if (!s) {
    return null;
  }
  const icon = s;
  if (compact) {
    if (!icon) {
      return null;
    }
    return <PuxIcon icon={icon} color="tertiary" />;
  }

  return (
    <span>
      {actionable && state === ApiProductState.Available
        ? t("General.Activate", { context: state })
        : t("General.ProductState", { context: state })}
    </span>
  );
}
