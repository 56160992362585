import { useHistory, generatePath } from "react-router-dom"
import { RouteKeys, routeKeys, routes } from "./routes"
import { useCurrentSubscriptionId } from "./useSubscriptionId"
import useAnalytics from "./useAnalytics"
import { useTranslation } from "react-i18next"
import { If, HasKey, GetKey } from "simplytyped"
import type {
  CostSharingProfileRuleType,
  ExpenseSubCategory,
} from "@phonero/common-graphql/types"
import { formatPeriodForUrl } from "../util/period"

export enum UrlHashes {
  dataInternational = "#data-int",
  dataInternationalSubscription = "#data-int-sub",
  data = "#data",
  dataSubscription = "#data-sub",
  change = "#change",
  edit = "#endre",
  move = "#flytt",
  moveSubscriptionCancellation = "#move-cancellation",
  innrapportering = "innrapportering",
  boost = "#boost",
  devView = "#dev-view",
  releaseSubscription = "#release",
  releaseSubscriptionCancellation = "#release-cancellation",
  sumAmountLimit = "#sum-limit",
  sendReceipt = "#send-receipt",
  terminateSubscription = "#terminateSubscription",
  terminateSubscriptionCancellation = "#terminateSubscriptionCancellation",
}

export const useDialoges = () => {
  const history = useHistory()
  const analytics = useAnalytics()

  const push =
    (
      buttonName: string,
      href?: string,
      replace?: boolean,
      keepSearchParams?: boolean
    ) =>
    (path?: string, state?: any) => {
      if (!href && !path) {
        return
      }
      let p = path || href || ""
      if (p.startsWith("#")) {
        p = window.location.pathname + p
        if (keepSearchParams === undefined && !p.includes("?")) {
          keepSearchParams = true
        }
      }
      if (keepSearchParams) {
        p = p.replace("#", window.location.search + "#")
      }
      analytics.logNavigation({ buttonName, href: p })
      if (replace) {
        history.replace(p, state)
      } else {
        history.push(p, state)
      }
    }

  return {
    data:
      (analyticsButtonName: string, replace?: boolean) =>
      ({
        international,
        singleSubscription,
        productId,
      }: {
        international?: boolean
        singleSubscription?: boolean
        productId?: string
      } = {}) => {
        const hash = international
          ? singleSubscription
            ? UrlHashes.dataInternationalSubscription
            : UrlHashes.dataInternational
          : singleSubscription
          ? UrlHashes.dataSubscription
          : UrlHashes.data

        const q = productId ? `?pid=productId` : ""
        push(analyticsButtonName, "<productId>" + hash, replace, true)(q + hash)
      },
    edit: (buttonName: string) => push(buttonName)(UrlHashes.edit),
    move: (buttonName: string) => push(buttonName)(UrlHashes.move),
    sendReceipt: (buttonName: string) =>
      push(buttonName)(UrlHashes.sendReceipt),
    change: (buttonName: string) => push(buttonName)(UrlHashes.change),
    devView: (buttonName: string) => push(buttonName)(UrlHashes.devView),
    boost: (buttonName: string) => push(buttonName)(UrlHashes.boost),
    costReporting: (buttonName: string) =>
      push(buttonName)(UrlHashes.innrapportering),
    releaseSubscription: (buttonName: string) =>
      push(buttonName)(UrlHashes.releaseSubscription),
    cancelReleaseSubscription: (buttonName: string) =>
      push(buttonName)(UrlHashes.releaseSubscriptionCancellation),
    terminateSubscription: (buttonName: string) =>
      push(buttonName)(UrlHashes.terminateSubscription),
    cancelTerminateSubscription: (buttonName: string) =>
      push(buttonName)(UrlHashes.terminateSubscriptionCancellation),
    cancelMoveSubscription: (buttonName: string) =>
      push(buttonName)(UrlHashes.moveSubscriptionCancellation),
    costAmountLimit: (buttonName: string) =>
      push(buttonName)(UrlHashes.sumAmountLimit),
  }
}

/** Typesafe appRouter for each path
 *
 * To add a path, please @see routes.ts
 * @example
 *
 * Navigates to the current subscription
 * <button onClick={() => goTo.subscription()} >Subscription (current)</button>
 *
 * Navigates to the chosen subscription
 * @example
 *
 * <button onClick={() => goTo.subscription(1234)} >Subscription 1234</button>
 *
 * Navigates to the chosen subscriptions-activity
 * @example
 * <button onClick={() => goTo.subscriptionActivity(1234)} >Activity (1234)</button>
 *
 * If you only want to get the path directly
 */
export function useAppRouter<Params extends NonNullable<unknown>>() {
  // TODO: since the routes no longer include the subscription
  // we should remove the dependency on the subscriptionId. It was a bad idea on my part. (subscriptionId should at most be prop)
  const history = useHistory()
  const analytics = useAnalytics({ debug: true })
  const dialogues = useDialoges()
  const { i18n } = useTranslation()
  const languageIndex = languageMap[i18n.language] || 0
  const { token, tokenSubscriptionId, subscriptionId } =
    useCurrentSubscriptionId<Params>()

  const args = {
    subscriptionId: tokenSubscriptionId,
  }
  /**  Go to */
  const goTo = (
    buttonName: string,
    /** @deprecated Please use the url-only */
    state?: any,
    additionalArgs?: Record<string, any>,
    replace?: boolean
  ) => {
    return goToCreator(
      (path) => {
        analytics.logNavigation({ buttonName, href: path })
        if (replace) {
          return history.replace(path, state)
        }
        return history.push(path, state)
      },
      languageIndex,
      { ...args, ...additionalArgs }
    )
  }
  const paths = goToCreator(null, languageIndex, args)
  return {
    paths,
    history,
    subscriptionId,
    tokenSubscriptionId,
    goTo,
    token,
    urlHash: (hash: UrlHashes) => history.push(hash),
    dialogues,
  }
}
type RoutePusher = (s: string, state?: any) => any

const parenRegex = /\([^)]*\)/g
function goToCreator(
  push: RoutePusher | null,
  languageIndex: number,
  params: Record<string, any>
) {
  return routeKeys.reduce(
    (r, key) => {
      r[key] = (...args: string[]) => {
        // Because some paths uses language-specific names (like
        // `(activity|aktivitet)`), we must replace the parenthesis and
        // choose one of the routes TODO: consider changing the original
        // path-function to an object.
        let path = (routes[key] as any).path.replace(parenRegex, (paren) => {
          const items = paren.slice(1, -1).split("|")
          return items[languageIndex] || items[items.length - 1]
        })
        if (params && path.includes(":")) {
          try {
            path = generatePath(path, params)
          } catch (err) {}
        }
        if (path[0] !== "/") {
          throw new Error(
            `Path must start with a '/', received '${path}' for routeKey '${key}'`
          )
        }
        if (args && args.length) {
          path = [path, ...args].join("/")
        }
        if (push) {
          if (path.includes(":")) {
            path = path.replace(/:\w*/g, "")
          }
          if (!path.includes("//")) {
            push(path)
          }
        }
        return path
      }
      return r
    },
    {} as {
      [key in RouteKeys]: (...args: string[]) => any
    }
  )
}

/** used to map of params used for navigation. Also works as typings for createRoutePath */
const RouteParams = {
  costTransferArchiveMonthDetailPage: (props: {
    /** IS THIS TYPE CORRECT? */
    period: string | Date | undefined
    /** IS THIS TYPE CORRECT? */
    category: ExpenseSubCategory | CostSharingProfileRuleType
  }) => {
    const shortPeriod = formatPeriodForUrl(
      props.period ? new Date(props.period) : new Date()
    )
    return {
      ...props,
      period: shortPeriod,
      category: props.category.toLowerCase(),
    }
  },
  costTransferArchiveMonthPage: (props: {
    period: string | Date | undefined
  }) => {
    const shortPeriod = formatPeriodForUrl(
      props.period ? new Date(props.period) : new Date()
    )
    return {
      ...props,
      period: shortPeriod,
    }
  },
  costTransferPeriodMonthDetailPage: (props: {
    category: ExpenseSubCategory | CostSharingProfileRuleType
    period: string | Date | undefined
  }) => {
    const shortPeriod = formatPeriodForUrl(
      props.period ? new Date(props.period) : new Date()
    )
    return {
      ...props,
      category: props.category.toLowerCase(),
      period: shortPeriod,
    }
  },
} as const

type P<K extends string> = If<
  HasKey<typeof RouteParams, K>,
  Parameters<GetKey<typeof RouteParams, K>>[0],
  Record<string, any>
>

/** Returns a route with a path ready for use with hrefs.
 * NOTE: Not all paths have typed parameters.
 * You can add a type in RouteParams
 * */
export const createRoutePath = <K extends RouteKeys>(key: K, params: P<K>) => {
  let languageIndex = 1
  let route = routes[key]
  let path = route.path.replace(parenRegex, (paren) => {
    const items = paren.slice(1, -1).split("|")
    return items[languageIndex] || items[items.length - 1]
  })
  if (params && path.includes(":")) {
    try {
      path = generatePath(
        path,
        key in RouteParams ? RouteParams[key as any](params) : params
      )
    } catch (err) {}
  }
  return { ...route, path }
}

export const languageMap = {
  en: 0,
  nb: 1,
}
