/** Hack for displaying the "correct" name for a subscription
 *
 * The name returned directly by the API is not translated.
 *
 * As a compromise, this currently uses the current mobileSubscriptions name as base for the naming
 * and adds size/sizetype as needed.
 *
 * Note that this means that it cannot be used with any other user.
 *
 * This also means that the name of the subscription really bears no meaning.
 */
export function subscriptionName(
  currentSubscription: { name?: any } | undefined | null,
  orderedProduct:
    | {
        size?: any
        sizeType?: any
        name?: any
        requestedProductName?: any
      }
    | undefined
    | null
) {
  const { name: currentName = "" } = currentSubscription || {}
  const {
    size = 0,
    sizeType = "GB",
    requestedProductName = "",
  } = orderedProduct || {}
  if (size || sizeType) {
    return `${currentName} ${size}${sizeType}`
  }
  return `${currentName} ${/[\d]*[KMGTP]B/.exec(requestedProductName)?.[0]}`
}
