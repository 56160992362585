import { PropsWithChildren } from "react"
import {
  PuxBackButton,
  PuxButtons,
  PuxIcon,
  PuxTitle,
  PuxToolbar,
} from "@phonero/pux-react"
import { PredefinedColors } from "@phonero/pux-core"
import { useGoBack, useBreakpointDesktop } from "@phonero/common-ux"
import { Link, useHistory, useLocation } from "react-router-dom"
import { arrowBack } from "@phonero/pux-icons/icons"
import { useLastLocation } from "react-router-last-location"

export interface PageTitleProps {
  color?: PredefinedColors
  backLink?: boolean | string | number
}
export const PageTitle = ({
  children,
  color = "light",
  backLink = true,
}: PropsWithChildren<PageTitleProps>) => {
  const { canNavigate: canGoBack, goBack } = useGoBack({
    ignoreHash: true,
  })
  const isDesktop = useBreakpointDesktop()
  const size = isDesktop ? "large" : undefined
  return (
    <PuxToolbar
      shadow
      color={color}
      style={{
        "--min-height": "8rem",
      }}
    >
      <PuxButtons slot="start">
        {backLink &&
          canGoBack &&
          (typeof backLink === "boolean" ? (
            <PuxBackButton style={{ margin: "0 1rem" }} onClick={goBack} />
          ) : (
            <BackButton href={backLink} />
          ))}
      </PuxButtons>
      <PuxTitle size={size} style={{ padding: 0 }}>
        <div
          id="title-portal-container"
          style={{
            justifyContent: "center",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          {children}
        </div>
      </PuxTitle>
    </PuxToolbar>
  )
}

const BackButton = ({ href }: { href: string | number }) => {
  const location = useLocation()
  const to =
    typeof href === "number"
      ? slice(location.pathname.split("/"), 0, -href).join("/")
      : href
  const lastLocation = useLastLocation()
  const lastUrl =
    lastLocation &&
    lastLocation.pathname + lastLocation.search + lastLocation.hash
  const goBack = to === lastUrl
  const history = useHistory()
  return (
    <Link
      style={{
        all: "unset",
        display: "inline-flex",
        alignItems: "center",
        paddingInlineStart: "var(--size-2)",
      }}
      to={to}
      {...(goBack && {
        onClick: (e) => {
          e.preventDefault()
          history.goBack()
        },
      })}
    >
      <PuxIcon
        style={{ fontSize: 22 }}
        icon={arrowBack}
        aria-hidden="true"
        lazy={false}
      />
      <div>Tilbake</div>
    </Link>
  )
}

const slice = <T extends any>(s: T[], start = 0, to = 0) => {
  const end = to < 0 ? s.length + to : to
  return s.slice(start, end)
}
