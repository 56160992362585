import { warning } from "@phonero/pux-icons/icons"
import { PuxButton, PuxIcon, toaster } from "@phonero/pux-react"
import { useOutstandingPayments, useAppTranslations, createRoutePath } from "@phonero/common-ux"
import { routes } from "../../routes"
import { Link, matchPath, useLocation } from "react-router-dom"
import css from "./styles.module.scss"
import { useMutation } from "@apollo/client"
import { CancelPaymentDocument } from "../../pages/paymentCallback/Vipps/OutstandingPayments.generated"
import { PaymentOperation } from "@phonero/common-graphql/types"

const OutstandingPayments = () => {
  const {
    purchases,
    remove,
    outstanding,
    firstOutstanding: first,
    count,
  } = useOutstandingPayments()
  const location = useLocation()
  const { t } = useAppTranslations()

  const color = "primary"

  const [cancel, _cancelResult] = useMutation(CancelPaymentDocument, {
    onError: (d) => toaster.error(d.message),
    onCompleted: (d) => {
      if (d.cancelPayment.errors) {
        toaster.error(
          "Det oppstod en feil: " +
            d.cancelPayment.errors.map((err) => err.message).join(" - ")
        )
        return
      }
      const lastOp = d.cancelPayment.paymentDetails?.lastState?.operation
      if (!lastOp) {
        toaster.error("Beklager, det oppstod en feil, manglende status")
        return
      }
      switch (lastOp) {
        case PaymentOperation.Cancelled:
        case PaymentOperation.Void:
          toaster.success("Ordren ble kansellert")
          d.cancelPayment.paymentDetails?.id &&
            remove(d.cancelPayment.paymentDetails?.id)
          break
        default:
          toaster.error(
            "Beklager, det oppstod en feil, uventet status: " + lastOp
          )
          return
      }
    },
  })
  if (!purchases) {
    return null
  }
  if (!first) {
    return null
  }
  const isPaymentCallback = matchPath(location.pathname, {
    path: routes.paymentCallbackVipps.path,
  })
  if (window.Cypress) {
    return null
  }
  if (isPaymentCallback) {
    return null
  }

  function onCancel() {
    if (!first) {
      return
    }
    cancel({
      variables: {
        input: {
          paymentId: first.paymentID as any, // TODO: fix any hack
          paymentMethod: first.paymentMethod,
        },
      },
    })
    remove(first?.paymentID as any /* TODO: Fix any hack */)
  }
  return (
    <div
      className={css.container}
      {...(_IS_DEV && {
        title: JSON.stringify(outstanding, null, 2),
      })}
      style={{
        background: `var(--pux-color-${color})`,
        color: `var(--pux-color-${color}-contrast)`,
      }}
    >
      <div>
        <PuxIcon icon={warning} />
      </div>
      <div>
        {t("PaymentCallBack.BannerText", {
          context: count === 1 ? "single" : "multiple",
          count: count,
          mdInline: false,
          ...first,
        })}
        <div className={css.buttons}>
          <Link
            to={
              createRoutePath("paymentCallbackVipps", {
                paymentID: first.paymentID,
              }).path
            }
          >
            <PuxButton fill="solid" color="light" shape="round" size="small">
              Fullfør
            </PuxButton>
          </Link>
          <PuxButton
            fill="outline"
            color="light"
            shape="round"
            size="small"
            onClick={onCancel}
          >
            Avbryt
          </PuxButton>
        </div>
      </div>
    </div>
  )
}

export default OutstandingPayments
