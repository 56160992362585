import "@phonero/pux-core/css/core.css"
import "@phonero/pux-core/css/dittphonero.css"
import "./index.css"

import { Suspense } from "react"
import { applyPolyfills, defineCustomElements } from "@phonero/pux-core/loader"
import { PuxApp, PuxErrorBoundary } from "@phonero/pux-react"
import { SplashScreen } from "@capacitor/splash-screen"

import reportWebVitals from "./reportWebVitals"
import App from "./App"
import "./logger"
import {
  Providers,
  MaintenanceMessageFetcher,
  MessageReceiver,
  MaintenanceBanner,
  OfflineStatus,
} from "@phonero/common-ux"
import AppError from "./pages/error/AppError"
import VersionCheck from "./VersionCheck"
import initializeI18n from "./initi18n"
import { InAppBrowser } from "./util/openLink"
import { createRoot } from "react-dom/client"
import * as appConstants from "./appConstants"
import campaignThemes from "./assets/themes"
import generatedIntrospection from "./util/fragmentTypes"
import { Capacitor } from "@capacitor/core"
//import { registerSW } from "virtual:pwa-register"
import { UpdateConfirm } from "./UpdateConfirm"

initializeI18n()

const root = createRoot(document.getElementById("root")!)

if (
  window["env"]["AUTH_ENDPOINT"] &&
  window["env"]["AUTH_ENDPOINT"] !== "" &&
  window["env"]["GRAPHQL_ENDPOINT"] &&
  window["env"]["GRAPHQL_ENDPOINT"] !== ""
) {
  root.render(
    <>
      {!Capacitor.isNativePlatform() && <UpdateConfirm />}
      <Suspense fallback="">
        <MaintenanceMessageFetcher receiver={MessageReceiver.DittPhonero} />
      </Suspense>
      <Suspense fallback="LOADING">
        <MaintenanceBanner />
        <Providers
          appConstants={appConstants}
          fragmentTypes={generatedIntrospection}
          campaignThemes={campaignThemes}
        >
          <PuxApp id="app" color="darkest" disableSwipeBack={false}>
            <PuxErrorBoundary component={AppError}>
              <VersionCheck />
              <App />
            </PuxErrorBoundary>
          </PuxApp>
          <OfflineStatus />
        </Providers>
      </Suspense>
    </>
  )

  applyPolyfills().then(() => {
    defineCustomElements(window)
  })

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
} else {
  root.render(<div>Missing configuration....</div>)
}

SplashScreen.hide({ fadeOutDuration: 500 })

if (Capacitor.isNativePlatform()) {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.map((r) => {
        r?.unregister()
      })
    })
  }
}

declare global {
  var BUILD: {
    VERSION: string
  }
  /** Indicates whether the dev-server is running. Note: This has nothing to do with which graphql-endpoint or auth-endpoint one uses */
  var _IS_DEV: boolean
  /** Indicates whether the the application is built for production. Note: This has nothing to do with which graphql-endpoint or auth-endpoint one uses */
  var _IS_PROD: boolean
  /** Indicates whether the the application is being run through a testrunner like vitest. Note: This has nothing to do with which graphql-endpoint or auth-endpoint one uses */
  var _IS_TEST: boolean
  interface Window {
    _IS_TEST: boolean
    __Cypress__?: boolean
    previousPath?: { pathname: string; hash: string; search: string }
    Cypress?: true
    playwright?: any
    cordova?: {
      /** https://cordova.apache.org/docs/en/10.x/reference/cordova-plugin-inappbrowser/ */
      InAppBrowser: InAppBrowser
    }
    __phonero__: {
      collectorApplicationName?: string
      debugAnalytics?: boolean
      graphqlEndpoint?: string
      authEndpoint?: string
      devEnv?: boolean
      collectorDisabled?: boolean
      /** URL to colelctor for use with Faro SDK */
      collectorUrl?: string
      /** ApiKey for use with Faro SDK */
      collectorApiKey?: string
      /** console-messages to ignore. If it starts with a '/', it will be treated as regexp.*/
      collectorIgnoreConsole?: string[]
      /** urls to ignore. If it starts with a '/', it will be treated as regexp.*/
      collectorIgnoreUrls?: string[]
      /** errors to ignore. If it starts with a '/', it will be treated as regexp.
       * UPDATE: this does not seem to work, even though it is the official way to filter errors.
       * See collectorBeforeSendObjPatterns
       * */
      collectorIgnoreErrors?: string[]
      /**
       * Before items are send, they can be filtered. The expected syntax is a Record of key/values,
       * where the key is the [object-path](https://www.npmjs.com/package/object-path) as a string
       * and the value is one or more strings or regexes.
       * If the value matches the value in that key, it would be removed.
       *
       *
       * @example
       * ```typescript
       *
       * const patternObjs = {
       *   "payload.message": ["ignore"],
       * }
       * ```
       *
       * */
      collectorBeforeSendObjPatterns?: Record<string, string[]>
      /**  Allows adding extra annotations to fields
       *   The key should be the label-name in the annotation, while the value should be the object-path of where to retreive the item.
       */
      collectorExtraAnnotation?: Record<string, string>
      stagingEnv?: boolean
      jeagerTemplate?: string
      keycloak?: any
      featureFlags?: Record<string, boolean>
      maintenanceMessageUrl?: string
    }
  }
}
// Remove the annoying log-statement
try {
  ;(window as any).__APOLLO_DEVTOOLS_GLOBAL_HOOK__ = true
} catch (e) {
  console.log(e)
}
