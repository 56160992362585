import React from "react"
import { PuxList, PuxSheet, PuxSkeletonText, PuxText } from "@phonero/pux-react"
import { DataPackage, ProductList, useAppTranslations } from "@phonero/common-ux"
import ExVatNotice from "./ExVatNotice"
import { AsDataPackageOfferingProductFragment } from "../../DataPackageQueries.generated"

export const FromProductWrapper = ({
  children,
  loading,
  products,
  currentProductPrice,
  widestAvailablePackageSize,
  userCannotChangeSubscription,
  currentSubscriptionProductId,
}: {
  children: any
  currentSubscriptionProductId: string
  widestAvailablePackageSize?: number | null
  loading: boolean
  products: Array<AsDataPackageOfferingProductFragment>
  currentProductPrice: number
  userCannotChangeSubscription: boolean
}) => {
  const { t } = useAppTranslations()

  return (
    <>
      <PuxSheet
        style={{ textAlign: "center", paddingBottom: 0 }}
        color={"light"}
      >
        <div>
          {!products?.length ? (
            <PuxSkeletonText animated />
          ) : userCannotChangeSubscription ? (
            <>
              <div>
                <PuxText>{t("ChangeSubscription.CannotSendApproval")}</PuxText>
              </div>
              <div>
                <PuxText>{t("ChangeSubscription.ContactAdmin")}</PuxText>
              </div>
            </>
          ) : (
            <ExVatNotice />
          )}
        </div>
      </PuxSheet>

      <PuxSheet color={"light"}>
        <PuxList accordion={true}>
          <h4
            style={{
              textAlign: "center",
              margin: "0px 0 10px",
              fontSize: "larger",
            }}
          >
            Fra
          </h4>
          <ProductList
            products={products
              .filter((p) => p.id === currentSubscriptionProductId)
              .map((p) => ({ ...p, price: currentProductPrice }))}
            placeholders={1}
            Component={(props) => (
              <DataPackage
                {...props}
                // setting the same width on the active product size as in the below list of available
                widestAvailablePackageSize={widestAvailablePackageSize}
                active={true}
              />
            )}
            animated={loading}
            color="dark"
          />
          <h4
            style={{
              textAlign: "center",
              margin: "40px 0 10px",
              fontSize: "larger",
            }}
          >
            Til
          </h4>
          {children}
        </PuxList>
      </PuxSheet>
    </>
  )
}
