import React, { FC } from "react"
import { PuxActionButton } from "@phonero/pux-react"
import { aboNew, plus, purchase } from "@phonero/pux-icons/icons"
import { useAppRouter } from "./util/useAppRouter"
import { dataCy, useAppTranslations, useHasSpeedBoost } from "./util"

const actionButtons = (
  isSpeedBoost?: boolean
): Array<{
  text: string
  icon?: string
  onClick: (r: ReturnType<typeof useAppRouter>) => any
}> => [
  {
    text: "Actions.RefillData",
    icon: plus,
    onClick: (r) =>
      r.dialogues.data("actionButtons")({
        international: isSpeedBoost,
        singleSubscription: true,
      }),
    ...dataCy("action-button-data"),
  },
  {
    text: "Routes.EditSubscription",
    icon: aboNew,
    onClick: (r) => r.dialogues.edit("actionButtons"),
    ...dataCy("action-button-edit"),
  },
  {
    text: isSpeedBoost ? "ExtraData.SpeedBoost" : "ExtraData.DataBoost",
    icon: purchase,
    onClick: (r) => r.dialogues.boost("actionButtons"),
    ...dataCy("action-button-boost"),
  },
]
const Wrapper = (p: JSX.IntrinsicElements["div"]) => (
  <div
    className="pux-color-primary"
    style={{
      display: "grid",
      gap: 20,
      gridAutoFlow: "column",
      gridTemplate: `repeat(${actionButtons().length}, 1fr)`,
      textAlign: "center",
      justifyContent: "center",
      alignItems: "baseline",
      fontWeight: 900,
      fontSize: "1.7rem",
    }}
    {...p}
  />
)

export const ActionButtonsOrig: FC = () => {
  const r = useAppRouter()
  const { t } = useAppTranslations()

  const hasSpeedBoost = useHasSpeedBoost()

  return (
    <Wrapper>
      {actionButtons(hasSpeedBoost).map(({ text, onClick, ...rest }) => (
        <React.Fragment key={text}>
          <PuxActionButton
            onClick={() => onClick(r)}
            children={t(text)}
            {...rest}
          />
        </React.Fragment>
      ))}
    </Wrapper>
  )
}
export const ActionButtons = React.memo(ActionButtonsOrig, () => true)
