import { useState } from "react"
import {
  DateFormat,
  PuxButton,
  PuxIcon,
  PuxModalContent,
  PuxSheet,
  PuxSpinner,
  PuxText,
  toaster,
} from "@phonero/pux-react"
import {
  ExtraDataPackageOfferingProduct,
  OrderStatusId,
  PaymentMethod,
  SortEnumType,
} from "@phonero/common-graphql/types"
import {
  useCurrentSubscriptionId,
  CodewordType,
  useAppTranslations,
  useAppMutation,
  useAppQuery,
  DataBoostTheme,
  DataPackageTheme,
  CheckoutProductHeader,
  Markdown,
  ShowLessOrAll,
  uncompletedPurchases,
  useAppRouter,
  useFaroClient,
  LogOrderProcessParams,
  OrdersDocument,
  InlineError,
  useSpan,
} from "@phonero/common-ux"
import css from "./Checkout.module.scss"
import { ordersVariables } from "../../dialogs/ChangeSubscriptionModal"
import { SubscriptionExtraDataPackagesDocument } from "../ExtraDataQueries.generated"
import { OrderStatus } from "./checkout/OrderStatus"
import { ConfirmContinue } from "./checkout/ConfirmContinue"
import { SelectPaymentMethod } from "./checkout/SelectPaymentMethod"
import { NoAvailableProducts } from "./checkout/NoAvailableProducts"
import {
  PurchaseExtraDataPackageDocument,
  AddExtraDataPackageDocument,
} from "./Checkout.generated"
import { getTheme } from "../../assets/themes"
import { featureFlags, isApp } from "../../appConstants"
import { Maybe } from "graphql/jsutils/Maybe"
import { getCurrentAppScheme } from "../../platform"

import { aboCheck } from "@phonero/pux-icons/icons"
import { SubscriptionExtraDataPackageDetailsDocument } from "./CheckoutExtraDataPackageDetails.generated"
import {
  paymentVariation,
  usePaymentVariation,
} from "./calculatePaymentVariation"

export interface CheckoutProps {
  product: Pick<
    ExtraDataPackageOfferingProduct,
    | "productId"
    | "theme"
    | "id"
    | "price"
    | "size"
    | "name"
    | "sizeType"
    | "type"
    | "roamingDurationDays"
    | "countries"
    | "expiryDate"
    | "campaign"
  > & {
    available?: Maybe<{ totalCount?: Maybe<number> }>
  }
  logOrderProcess: (args: Partial<LogOrderProcessParams>) => void
  onClose: () => any
  onCancel: () => any
  onOrderStatusChange?: (
    prev: OrderStatusId | undefined | null,
    next: OrderStatusId
  ) => any
}

export const Checkout = ({
  onClose,
  onCancel,
  product,
  onOrderStatusChange,
  logOrderProcess,
}: CheckoutProps) => {
  if (!product) throw new Error("Product to checkout missing")
  const faroClient = useFaroClient()
  const { t, i18n } = useAppTranslations()
  const otel = useSpan("trace", "checkout")
  const { subscriptionId } = useCurrentSubscriptionId()
  const { goTo } = useAppRouter()
  const isAbroad = window.location.hash === "data-int"
  const [payUsingVipps, setPayUsingVipps] = useState<boolean>()
  if (!product?.productId) {
    throw Error("product id is missing")
  }

  const isCampaignProduct = !!product?.theme
  const theme = isCampaignProduct ? getTheme(product.theme) : undefined

  const isDataPackageCampaign = theme?.type === CodewordType.ExtraDataEEA
  // || theme?.type === CodewordType.ExtraBoost // TODO uncomment this

  const dataTheme = isDataPackageCampaign
    ? (theme as DataPackageTheme)
    : undefined

  const boostTheme =
    theme && !isDataPackageCampaign ? (theme as DataBoostTheme) : undefined

  // nosemgrep: semgrep.gql-missing-error-check-mutation-deconstructed-warning
  const [purchaseExtraDataPackage, purchaseResults] = useAppMutation(
    PurchaseExtraDataPackageDocument,
    {
      onCompleted: (d) => {
        // Redirect user automatically to payemnt-providers url
        // (there is also a fallbackLink below)
        if (d.purchaseExtraDataPackage.errors?.length) {
          return
        }
        const { purchaseRequest } = d.purchaseExtraDataPackage
        if (!purchaseRequest || !purchaseRequest?.paymentURI) {
          return
        }

        logOrderProcess({
          stepName: "confirm-purchase",
          attributes: {
            paymentMethod: purchaseRequest.paymentMethod,
            ...(purchaseRequest.paymentMethod === PaymentMethod.Vipps && {
              productID: purchaseRequest.productID,
              orderID: purchaseRequest.orderID,
            }),
          },
        })
        if (purchaseRequest?.paymentMethod === PaymentMethod.Vipps) {
          if (otel.current) {
            otel.current.span.setAttributes({
              paymentMethod: purchaseRequest.paymentMethod,
              productID: purchaseRequest.productID,
              orderID: purchaseRequest.orderID,
            })
            otel.current.span.setAttribute(
              "paymentMethod",
              purchaseRequest.paymentMethod
            )
            otel.current.span.setAttribute(
              "paymentID",
              purchaseRequest.paymentID
            )
            otel.current.span.setAttribute("orderID", purchaseRequest.orderID)
            otel.current.span.setAttribute(
              "productID",
              purchaseRequest.productID
            )
          }
          // Redirect to the callback-page right away.
          // This is in case  there is a problem with redirecting the user from the vipps-app and into our app again
          goTo("vipps-redirect", null, {
            paymentID: purchaseRequest.paymentID,
          }).paymentCallbackVipps()
          // Store the purchase-details into local storage
          uncompletedPurchases.add({
            ...purchaseRequest,
            source: "localStorage",
            productText: product.name,
            amount: product.price * 1.25,
            date: new Date(),
          })
          // Redirect the user to the vipps-page/app, but give the router a bit of time to handle the previous redirect
          // (onClose will probably also have been called)
          setTimeout(() => {
            window.location.href = purchaseRequest.paymentURI!
          }, 150)
        }
      },
    }
  )
  const [addExtraDataPackage, { loading, data: orderData, error }] =
    useAppMutation(AddExtraDataPackageDocument, {
      onCompleted: (d) => {
        if (!d.addExtraDataPackage?.orderId) {
          return
        }
        logOrderProcess({
          stepName: "confirm-purchase",
          attributes: { orderId: d.addExtraDataPackage.orderId },
        })
      },
      refetchQueries: [
        // ChangeSubscriptionDialog-query
        {
          query: OrdersDocument,
          variables: ordersVariables(subscriptionId),
        },
        // OrdersBadge-query
        {
          query: OrdersDocument,
          variables: {
            first: 50,
            orderBy: {
              registrationDateTime: SortEnumType.Desc,
            },
            subscriptionID: subscriptionId,
            // TODO: when api supports filtering by date, we should only query those we actually need, and only request the count here
            withDetails: true,
          },
        },
        {
          query: SubscriptionExtraDataPackagesDocument,
          variables: {
            subscriptionId: subscriptionId,
          },
        },
      ],
    })
  // the api will replace this template-values
  const vippsCallback =
    getCurrentAppScheme() +
    `paymentCallback/{{type}}/{{orderId}}?sessionID=${
      faroClient?.metas?.value?.session?.id || ""
    }`

  const checkout = async (method: PaymentMethod | "newVipps") => {
    if (otel.current) {
      otel.current.span.addEvent("checkout", { paymentMethod: method })
    }
    if (
      product.campaign &&
      !showConfirmationPage &&
      method === PaymentMethod.Mobile
    ) {
      setShowConfirmationPage(true)
      return
    }
    if (!subscriptionId || !product.id) {
      console.error("Some required keys were missing", {
        subscriptionId,
        product,
      })
      toaster.error(t("General.UnspecifiedError"))
      return
    }

    const vipps = method === PaymentMethod.Vipps || method === "newVipps"
    setPayUsingVipps(vipps)
    setShowConfirmationPage(false)
    if (method === "newVipps" && featureFlags.vipps) {
      await purchaseExtraDataPackage({
        variables: {
          input: {
            isApp,
            subscriptionId,
            productId: String(product.productId),
            paymentMethod: vipps ? PaymentMethod.Vipps : PaymentMethod.Mobile,
            callbackUrl: vippsCallback,
            ...(featureFlags.devVippsCallbackRedirectstoHome && {
              callbackUrl: window.location.origin + "/",
            }),

            price: product.price,
          },
        },
      })
      return
    }
    await addExtraDataPackage({
      variables: {
        input: {
          subscriptionId,
          productId: String(product.productId),
          payUsingVipps: vipps,
        },
      },
    })
  }

  const [showConfirmationPage, setShowConfirmationPage] =
    useState<boolean>(false)

  const showNotAvailablePage = product.available?.totalCount === 0

  function getProductDescription(
    product?: Pick<
      ExtraDataPackageOfferingProduct,
      "description" | "size" | "type" | "sizeType" | "roamingDurationDays"
    >
  ) {
    if (!!product?.description) {
      return <Markdown>{product?.description}</Markdown>
    } else if (!!dataTheme?.description) {
      return <Markdown>{dataTheme.description}</Markdown>
    } else {
      return t("ExtraData.ProductDescription", {
        size: product?.size,
        ...(product?.type !== undefined && {
          context: `${CodewordType[product.type]}${
            !!product.roamingDurationDays ? "_HIDE_DURATION" : ""
          }${
            product.type === CodewordType.SpeedBoost
              ? "_" + product.sizeType
              : ""
          }`,
        }),
      })
    }
  }

  const getProductTitleForBoostProduct = (
    size: number,
    productType: CodewordType | undefined,
    sizeType: "GB" | "HOUR" | undefined
  ) => (
    <>
      <div style={{ fontSize: "5rem", marginRight: 10 }}>{product.size}</div>
      <div style={{ fontWeight: "normal" }}>
        {t("ExtraData.SizeType", {
          context: sizeType,
          count: product.size,
        })}{" "}
        <span style={{ textTransform: "lowercase" }}>
          {t("ExtraData.UnlimitedData", { context: productType?.toString() })}
        </span>
      </div>
    </>
  )
  // Do we really need this query?
  const {
    data: productData,
    loading: loadingProductData,
    error: productDataError,
  } = useAppQuery(SubscriptionExtraDataPackageDetailsDocument, {
    skip: !subscriptionId,
    variables: { subscriptionId, productId: product.productId + "" },
  })
  const firstProduct = productData?.mobileSubscription?.products?.[0] || product
  // If any product has vipps only set then vipps only is set for that customer
  const privateOnlyPayments =
    !!firstProduct && "vippsOnly" in firstProduct && firstProduct.vippsOnly

  let { variation, error: paymentVariationError } = usePaymentVariation({
    kind: isAbroad ? "abroad" : "domestic",
    privateOnly: privateOnlyPayments,
  })

  return (
    <PuxModalContent>
      <CheckoutProductHeader
        title={
          {
            [CodewordType.ExtraDataInternational]: `${product.name} (${product.size} ${product.sizeType})`,
            [CodewordType.ExtraDataEEA]: `${product.size} ${product.sizeType} Ekstra Data`,
            [CodewordType.ExtraBoost]: getProductTitleForBoostProduct(
              product.size,
              product.type,
              product.sizeType as any
            ),
            [CodewordType.SpeedBoost]: getProductTitleForBoostProduct(
              product.size,
              product.type,
              product.sizeType as any
            ),
          }[product.type!] || product.name
        }
        {...(isDataPackageCampaign && {
          title: `${product.size} ${t("ExtraData.SizeType", {
            context: product.sizeType,
            count: product.size,
          })} ${product.name}`,
        })}
        {...((product.type === CodewordType.ExtraBoost ||
          isDataPackageCampaign) && {
          variant: "boxed",
        })}
        {...((product.type === CodewordType.ExtraBoost ||
          product.type === CodewordType.SpeedBoost) && {
          variant: "boxed",
        })}
        priceExVat={product.price ?? undefined}
        dataTheme={dataTheme}
        boostTheme={boostTheme}
        descriptions={[
          <>
            {product.type === CodewordType.ExtraDataInternational && (
              <div>
                {t("ExtraData.Included", {
                  count: product.size,
                  sizeType: product.sizeType,
                })}
              </div>
            )}
            {product.roamingDurationDays && (
              <div>
                {t("ExtraData.DurationDays", {
                  context: product.roamingDurationDays !== 1 ? "plural" : "",
                  count: product.roamingDurationDays,
                })}
              </div>
            )}
            <div className={css.richDescription}>
              {getProductDescription(product)}
            </div>
            {product?.type === CodewordType.ExtraDataEEA && (
              <div>
                <br />
                <PuxText>{t("ExtraData.NoSatShip")}</PuxText>
              </div>
            )}
          </>,
          !!product.countries && product.countries.length && (
            <>
              <em>{t("ExtraData.Countries")}</em>
              <ShowLessOrAll
                items={
                  product.countries
                    ?.filter((c) => !!c)
                    .map((c) => c as string) ?? []
                }
              />
            </>
          ),
        ]}
      />

      <PuxSheet style={{ textAlign: "center" }} noPadding>
        {!!error?.message && (
          <div>{"Bestillingen kunne ikke gjennomføres."}</div>
        )}
        {!!orderData && !!orderData.addExtraDataPackage?.orderId && (
          <OrderStatus
            id={orderData.addExtraDataPackage?.orderId}
            subscriptionId={subscriptionId}
            payUsingVipps={!!payUsingVipps}
            onOrderStatusChange={onOrderStatusChange}
            onClose={onClose}
          />
        )}
        <InlineError
          error={purchaseResults.error}
          code="PurchaseExtraDataPackage"
        />
        <InlineError error={paymentVariationError} code="PaymentVariation" />
        {!!purchaseResults.data?.purchaseExtraDataPackage.errors?.length && (
          <ul>
            {purchaseResults.data.purchaseExtraDataPackage.errors.map(
              ({ message }, i) => {
                return <li key={i}>{message}</li>
              }
            )}
          </ul>
        )}
        {!orderData && showNotAvailablePage && <NoAvailableProducts />}
        {!orderData && !showNotAvailablePage && (
          <>
            {!!purchaseResults.data?.purchaseExtraDataPackage?.purchaseRequest
              ?.paymentURI && (
              <div className={css.fallbackLink}>
                {/* Link fades in with a delay in css */}
                {t("ExtraData.RedirectFallbackMessage", {
                  mdInline: false,
                  paymentMethod: payUsingVipps && PaymentMethod.Vipps,
                  url: purchaseResults.data.purchaseExtraDataPackage
                    .purchaseRequest.paymentURI,
                })}
              </div>
            )}
            {!purchaseResults.data?.purchaseExtraDataPackage.errors && (
              <>
                {!showConfirmationPage || payUsingVipps ? (
                  !!product.price ? (
                    <>
                      <InlineError
                        error={productDataError}
                        code="SubscriptionExtraDataPackageDetails"
                      />
                      <CostSharingMessage variant={variation} />
                      {!purchaseResults.data?.purchaseExtraDataPackage
                        .purchaseRequest ? (
                        <SelectPaymentMethod
                          variant={variation}
                          loadingProductData={loadingProductData}
                          subscriptionId={subscriptionId}
                          product={product}
                          onPaymentClick={checkout}
                          disabled={
                            loading ||
                            purchaseResults.loading ||
                            loadingProductData
                          }
                        />
                      ) : null}
                      {(loading || purchaseResults.loading) && <PuxSpinner />}
                    </>
                  ) : (
                    <ConfirmContinue
                      title={
                        dataTheme?.orderButtonText ??
                        "Aktiver min " + product?.name
                      }
                      onClick={() => {
                        if (isDataPackageCampaign) {
                          checkout(PaymentMethod.Mobile)
                        } else {
                          setShowConfirmationPage(true)
                        }
                      }}
                      onCancel={onCancel}
                      theme={theme}
                      disabled={!!error}
                    />
                  )
                ) : (
                  <>
                    {isCampaignProduct ? (
                      <ConfirmContinue
                        title={"Bekreft"}
                        onClick={() => checkout(PaymentMethod.Mobile)}
                        onCancel={onCancel}
                        theme={theme}
                        disabled={!!error}
                      />
                    ) : (
                      <>
                        <p>
                          <PuxButton
                            expand="block"
                            className={css.button}
                            color="primary"
                            onClick={() => checkout(PaymentMethod.Mobile)}
                            disabled={!!error}
                          >
                            Kjøp
                          </PuxButton>
                        </p>
                        <p>
                          <PuxButton
                            expand="block"
                            color={"light"}
                            className={css.button}
                            onClick={() => setShowConfirmationPage(false)}
                          >
                            Avbryt
                          </PuxButton>
                        </p>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {isCampaignProduct && !!product?.expiryDate && (
              <PuxText className={css.expiryDate}>
                Kampanjen utløper{" "}
                {i18n.format(product?.expiryDate, DateFormat.Date)}
              </PuxText>
            )}
          </>
        )}
      </PuxSheet>
    </PuxModalContent>
  )
}

const CostSharingMessage = ({ variant }: { variant: paymentVariation }) => {
  const { t } = useAppTranslations()
  const tKey = "ExtraData.CostsharingMessage"
  const text = t(tKey, {
    context: String(variant),
    costLink: "/foo",
    skipMarkdown: true,
  }).trim()
  if (!text || text === tKey) {
    return null
  }
  return (
    <div className={css.iconMessage} data-cy="costMessage">
      <div>
        <PuxIcon icon={aboCheck} color="primary" size="large" />
      </div>
      <div className="typography">
        <Markdown>{text}</Markdown>
      </div>
    </div>
  )
}
