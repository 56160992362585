import { useRouteMatch, useAppTranslations } from "@phonero/common-ux"
import { PageTitle } from "."

/** Displays the title automatically */
function PageHeader({
  backLink,
  title,
}: {
  backLink?: boolean | string | number
  title?: string
}) {
  const match = useRouteMatch()
  const { t } = useAppTranslations()
  if (match.key === "home") return null
  if (!("route" in match)) {
    return null
  }
  return (
    <PageTitle backLink={backLink ?? !match.route?.noBackLink}>
      {title ?? t(match.route.name)}
    </PageTitle>
  )
}

export default PageHeader
