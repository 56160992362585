import { ArgsAsTuple } from "simplytyped"
import { AppRoute, routeKeys, RouteKeys, routes } from "./routes"
import { matchPath, useLocation } from "react-router-dom"
import { toaster } from "@phonero/pux-react"

/** used to extract the @see Route (routes.ts) from the current path.
 *
 *
 * for pure usage, @see matchRoute-function
 * */
export function useRouteMatch(
  requirements?: ArgsAsTuple<typeof matchRoute>[1]
): RouteMatchResult & { location: ReturnType<typeof useLocation> } {
  const location = useLocation()

  return { location, ...(matchRoute(location.pathname, requirements) as any) }
}
type RouteMatchResult = {
  route: AppRoute
  key: string
  match: ReturnType<typeof matchPath>
  ok: boolean
}
// Could use a Map here, but there are fairly few items, so I doubt it matters
const matchRouteCache: Record<string, RouteMatchResult> = {}

/** Returns the first path and most strict match for a matching route.*/
export function matchRoute(
  pathname: string,
  requirements: Partial<Pick<AppRoute, "isPublic">> = {}
): RouteMatchResult | null {
  // This function is called a lot. Cached for performance.
  // Consider using a memoization-library if more cache is needed throughout the code.
  const cacheKey = pathname + (requirements.isPublic ? "1" : "")
  if (matchRouteCache[cacheKey] !== undefined) {
    return matchRouteCache[cacheKey]
  }
  for (const key of routeKeys) {
    const route = routes[key]
    const ok =
      requirements.isPublic !== undefined &&
      requirements.isPublic === !!route.isPublic
    const didMatch = matchPath(pathname, { path: route.path })
    if (didMatch) {
      matchRouteCache[cacheKey] = {
        route,
        key,
        match: didMatch,
        ok,
      }
      return matchRouteCache[cacheKey]
    }
  }

  // Fallback, should not really happen
  if (process.env.NODE_ENV === "development") {
    const message = `DEVELOPER: There was a problem matching the route. The fallback-route was returned instead`
    console.warn(message)
    toaster.error(message)
  }
  const key: RouteKeys = "noSubscriptionPage"
  const route = routes[key]
  matchRouteCache[cacheKey] = {
    key,
    route,
    ok: false,
    match: null as any,
  }
  return matchRouteCache[cacheKey]
}
