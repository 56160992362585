import { toaster } from "@phonero/pux-react"
import { objectKeys } from "simplytyped"
import { hasCostTransferAccess } from "./useAppStateCommon"

/**
 * About these routes:
 *
 * These routes are use both by the application-router, and for typesafe navigation.
 *
 * To not have to duplicate the path-paramater for these usages, the path-key is a function.
 * Passing null/undefined as parameters is intended for the router, while other values should be used while navigating
 *
 * IMPORTANT: Order matters
 *
 * The router should have the most precise routes at the top, while the most general at the bottom.
 * This is to work better with the routers Switch-component, without needing to use `exact`
 */

export type AppRoute = {
  /** The path used by the router */
  path: string
  /** A string representing the name of the route. Will be used in titles, etc. */
  name: string
  /** If true, the route will not require authentication */
  isPublic?: boolean
  /** If true, no back-button will be present */
  noBackLink?: boolean
  /** If set, can be used to verify access before entering the page. */

  restrictions?: () =>
    | boolean
    | React.ReactNode
    | {
        /** If set, will redirect to the page. The value should be a RouteKey.*/
        redirect: string
        /** If set, will send to user to the login-flow */
        forceLogin: boolean
      }
}

//const subscriptionPath = ":subscriptionId"
/** A mapping of all routes within the application */
export const routes = {
  /** Payment-callback for vipps.
  This page is only used when a user has paid for an item with Vipps.
  
  The payment is either completed successfully, or failed.
*/
  paymentCallbackVipps: {
    path: "/paymentCallback/Vipps/:paymentID",
    name: "Routes.PaymentCallback",
    noBackLink: true,
    isPublic: true,
  } as AppRoute,
  playground: {
    path: "/playground",
    name: "Playground",
    isPublic: true,
    restrictions: () => (_IS_DEV ? true : "Finner ikke siden"),
  } as AppRoute,
  /** Contact-page, showing contact information for customer-support etc. */
  contact: {
    path: "/(contact|kontakt)",
    name: "Routes.Contact",
    isPublic: true,
  } as AppRoute,
  /** Welcome-page */
  welcome: {
    path: `/(welcome|velkommen)`,
    name: "Routes.Welcome",
    isPublic: true,
    noBackLink: true,
  } as AppRoute,
  /** Subscription-page (Abonnement) */
  subscription: {
    path: `/(subscription|abonnement)`,
    name: "Routes.Subscription",
  } as AppRoute,
  /** Services (Tjenester) */
  subscriptionServices: {
    path: `/(services|tjenester)`,
    name: "Routes.SubscriptionServices",
  } as AppRoute,
  /** Services (Innstillinger) */
  settings: {
    path: `/(manage|administrer)`,
    name: "Routes.Settings",
  } as AppRoute,
  /** Usage (Forbruk) */
  subscriptionUsage: {
    path: `/(usage|forbruk)/:period?`,
    name: "Routes.SubscriptionUsage",
  } as AppRoute,
  /** Usage report (Innrapportering) */
  costTransferPage: {
    path: `/(usage|forbruk)/innrapportering`,
    name: "Routes.CostTransferPage",
    restrictions: () => {
      const ok = hasCostTransferAccess()
      switch (ok) {
        case undefined:
          return "Venter..."
        case "loading":
          return "Laster rettigheter..."
        case true:
          return true
        case false:
      }

      toaster.error(ok as any)
      return { redirect: "/forbruk" }
    },
  } as AppRoute,
  /** Arkivdetaljer (Innrapportering) */
  costTransferArchiveMonthPage: {
    path: `/(usage|forbruk)/innrapportering/arkiv/:period`,
    name: "Arkiv",
    restrictions: () => {
      const ok = hasCostTransferAccess()
      switch (ok) {
        case undefined:
          return "Venter..."
        case "loading":
          return "Laster rettigheter..."
        case true:
          return true
        case false:
      }
      toaster.error(ok as any)
      return { redirect: "/forbruk" }
    },
  } as AppRoute,
  /** Arkivdetaljer (Innrapportering) */
  costTransferArchive: {
    path: `/(usage|forbruk)/innrapportering/(arkiv)/`,
    name: "Arkiv",
    restrictions: () => {
      const ok = hasCostTransferAccess()
      switch (ok) {
        case undefined:
          return "Venter..."
        case "loading":
          return "Laster rettigheter..."
        case true:
          return true
        case false:
      }
      toaster.error(ok as any)
      return { redirect: "/forbruk" }
    },
  } as AppRoute,
  /** Kostnadsdetaljer arkiv (Innrapportering) */
  costTransferArchiveMonthDetailPage: {
    path: `/(usage|forbruk)/innrapportering/arkiv/:period?/detail/:category`,
    name: "Arkiv",
    restrictions: () => {
      const ok = hasCostTransferAccess()
      switch (ok) {
        case undefined:
          return "Venter..."
        case "loading":
          return "Laster rettigheter..."
        case true:
          return true
        case false:
      }
      toaster.error(ok as any)
      return { redirect: "/forbruk" }
    },
  } as AppRoute,
  /** Kostnadsdetaljer arkiv (Innrapportering) */
  costTransferPeriodMonthDetailPage: {
    path: `/(usage|forbruk)/innrapportering/:period?/:category`,
    name: "Denne perioden",
    restrictions: () => {
      const ok = hasCostTransferAccess()
      switch (ok) {
        case undefined:
          return "Venter..."
        case "loading":
          return "Laster rettigheter..."
        case true:
          return true
        case false:
      }
      toaster.error(ok as any)
      return { redirect: "/forbruk" }
    },
  } as AppRoute,
  /** Activity (Aktivitetslogg) */
  subscriptionActivity: {
    path: `/(activity|aktivitet)`,
    name: "Routes.SubscriptionActivity",
  } as AppRoute,
  /** Activity (Mine kjøp) */
  receipt: {
    path: `/(receipts|kvitteringer)/:orderId`,
    name: "Routes.Receipt",
  } as AppRoute,
  myReceipts: {
    path: `/(receipts|kvitteringer)`,
    name: "Routes.Receipts",
  } as AppRoute,
  /** Requests (Forespørsler) */
  subscriptionRequests: {
    path: `/(requests|foresporsler|godkjenn)`,
    name: "Routes.SubscriptionRequests",
  } as AppRoute,
  /** Home-page */
  home: {
    path: `/`,
    name: "Routes.Home",
  } as AppRoute,
  /** 404 / No subscription
   * TODO: Differentaiate between a 404, and a Route where subscriptions do not match.
   * */
  noSubscriptionPage: {
    path: "",
    name: "404",
    isPublic: true,
  } as AppRoute,
}

export type RouteKeys = keyof typeof routes
/** Sorted by the most specific routes first 
  Should probably just sort them manually if/when this breaks down

*/
export const routeKeys = objectKeys(routes).sort((a, b) => {
  let A = routes[a].path.split("/").length
  let B = routes[b].path.split("/").length
  if (A > B) {
    return -1
  }
  if (A < B) {
    return 1
  }
  A = routes[a].path.split(":").length
  B = routes[b].path.split(":").length
  if (A > B) {
    return 1
  }
  if (A < B) {
    return -1
  }
  return 0
})

/** Sorted by the most specific routes first */
export const routeArray = routeKeys.map((k) => routes[k])
