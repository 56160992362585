
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CancelPaymentError": [
      "ECommerceError",
      "FieldRequiredError",
      "InvalidArgumentError",
      "InvalidVippsIDError",
      "NotFoundOrNoAccessError",
      "PreconditionNotMetError"
    ],
    "ContractUnionType": [
      "CostSharingContract",
      "FamilyBucketContract",
      "GeneralContract",
      "MbnNordicomContract",
      "MobileBroadbandContract",
      "MobileContract"
    ],
    "Error": [
      "ECommerceError",
      "FieldRequiredError",
      "InvalidArgumentError",
      "InvalidVippsIDError",
      "NotFoundOrNoAccessError",
      "NotImplementedError",
      "OutdatedPriceError",
      "PreconditionNotMetError"
    ],
    "GenericSecondaryState": [
      "InternalPortingSecondaryState",
      "MoveSecondaryState",
      "PortingInSecondaryState",
      "ReleaseSubscriptionSecondaryState",
      "SubscriptionStatusSecondaryState"
    ],
    "IContract": [
      "CostSharingContract",
      "FamilyBucketContract",
      "GeneralContract",
      "MbnNordicomContract",
      "MobileBroadbandContract",
      "MobileContract"
    ],
    "ICost": [
      "CompanyCost",
      "PrivateCost"
    ],
    "IExpenseSet": [
      "CostSharingExpensePeriode",
      "ExpenseSet"
    ],
    "IOfferingProduct": [
      "AutoPackageOfferingProduct",
      "DataPackageOfferingProduct",
      "ExtraDataPackageOfferingProduct",
      "FamilyBucketDataOfferingProduct",
      "GeneralOfferingProduct"
    ],
    "IProduct": [
      "AutoPackage",
      "AutoPackageOfferingProduct",
      "AutoPackageProduct",
      "Barring",
      "BarringProduct",
      "CostControl",
      "CostControlProduct",
      "CostSharingSubscription",
      "CostSharingUserProduct",
      "CostSharingUserService",
      "DataPackage",
      "DataPackageOfferingProduct",
      "DataPackageProduct",
      "DataRollover",
      "DataRolloverProduct",
      "DataUsageNotification",
      "DataUsageNotificationProduct",
      "ExtraDataPackage",
      "ExtraDataPackageOfferingProduct",
      "ExtraDataPackageProduct",
      "FamilyBucketData",
      "FamilyBucketDataOfferingProduct",
      "FamilyBucketDataProduct",
      "FamilyBucketSubscription",
      "FamilyBucketUpgradeFee",
      "FamilyBucketUpgradeFeeProduct",
      "FamilyBucketUser",
      "FamilyBucketUserProduct",
      "GeneralOfferingProduct",
      "GeneralProduct",
      "HiddenProduct",
      "MbnUser",
      "MbnUserProduct",
      "MobileInsurance",
      "MobileInsuranceProduct",
      "MobileSubscription",
      "RoamLikeAtHomeExtension",
      "RoamLikeAtHomeExtensionProduct",
      "SimCard",
      "SimCardOrder",
      "SimCardProduct",
      "SubscriptionProduct",
      "TrafficIncludedOnSubscription",
      "TrafficIncludedOnSubscriptionProduct",
      "UnlimitedMinutes",
      "UnlimitedMinutesProduct"
    ],
    "IService": [
      "AutoPackage",
      "Barring",
      "CostControl",
      "CostSharingSubscription",
      "CostSharingUserService",
      "DataPackage",
      "DataRollover",
      "DataUsageNotification",
      "ExtraDataPackage",
      "FamilyBucketData",
      "FamilyBucketSubscription",
      "FamilyBucketUpgradeFee",
      "FamilyBucketUser",
      "MbnUser",
      "MobileInsurance",
      "MobileSubscription",
      "RoamLikeAtHomeExtension",
      "SimCard",
      "SubscriptionProduct",
      "TrafficIncludedOnSubscription",
      "UnlimitedMinutes"
    ],
    "IStreetAddress": [
      "CompanyDirectoryEntry",
      "PersonalDirectoryEntry"
    ],
    "OfferingProduct": [
      "AutoPackageOfferingProduct",
      "DataPackageOfferingProduct",
      "ExtraDataPackageOfferingProduct",
      "FamilyBucketDataOfferingProduct",
      "GeneralOfferingProduct"
    ],
    "OrderPaymentCallbackError": [
      "ECommerceError",
      "FieldRequiredError",
      "InvalidArgumentError",
      "InvalidVippsIDError",
      "NotFoundOrNoAccessError",
      "NotImplementedError"
    ],
    "ProductUnionType": [
      "AutoPackage",
      "AutoPackageProduct",
      "Barring",
      "BarringProduct",
      "CostControl",
      "CostControlProduct",
      "CostSharingUserProduct",
      "CostSharingUserService",
      "DataPackage",
      "DataPackageProduct",
      "DataRollover",
      "DataRolloverProduct",
      "DataUsageNotification",
      "DataUsageNotificationProduct",
      "ExtraDataPackage",
      "ExtraDataPackageProduct",
      "FamilyBucketData",
      "FamilyBucketDataProduct",
      "FamilyBucketUpgradeFee",
      "FamilyBucketUpgradeFeeProduct",
      "FamilyBucketUser",
      "FamilyBucketUserProduct",
      "GeneralProduct",
      "HiddenProduct",
      "MbnUser",
      "MbnUserProduct",
      "MobileInsurance",
      "MobileInsuranceProduct",
      "RoamLikeAtHomeExtension",
      "RoamLikeAtHomeExtensionProduct",
      "SimCard",
      "SimCardOrder",
      "SimCardProduct",
      "SubscriptionProduct",
      "TrafficIncludedOnSubscription",
      "TrafficIncludedOnSubscriptionProduct",
      "UnlimitedMinutes",
      "UnlimitedMinutesProduct"
    ],
    "PurchaseExtraDataPackageError": [
      "FieldRequiredError",
      "InvalidArgumentError",
      "NotImplementedError",
      "OutdatedPriceError"
    ]
  }
};
      export default result;
    