import React from "react"
import { IProduct } from "@phonero/common-graphql/types"
import { PuxButton, PuxSheet } from "@phonero/pux-react"
import { OrderStatusInline } from "../../../pages/activity/dataMaps"
import { QueryResult } from "@apollo/client"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import {
  AsDataPackageOfferingProductFragment,
  SubscriptionDataPackagesQuery,
  SubscriptionDataPackagesQueryVariables,
} from "../../DataPackageQueries.generated"
import { featureFlags } from "../../../appConstants"
import {
  sizeMultiplier,
  sortDataProducts,
  DataPackage,
  ProductList,
  useAppTranslations,
  isPotentialOffering,
} from "@phonero/common-ux"

export const ShowActivationMessage = ({ orderedName, lastActiveOrder }) => (
  <div
    style={{
      textAlign: "center",
      color: "var(--color-primary)",
    }}
  >
    <p>
      Det er allerede registrert en ordre på en endring til: {orderedName}
      {!!lastActiveOrder?.status && (
        <>
          {" "}
          som er{" "}
          <OrderStatusInline
            noIcon={true}
            status={lastActiveOrder.status}
            style={{ color: "inherit", textTransform: "lowercase" }}
          />
          {"."}
        </>
      )}
    </p>
  </div>
)

export const ChooseDataProduct = ({
  currentPrice,
  loading,
  data,
  onSetSelected,
  disabled,
  currentSubscriptionProductId,
  widestAvailablePackageSize,
}: {
  currentPrice: number
  onSetSelected: (product: Partial<IProduct>) => void
  currentSubscriptionProductId: string
  disabled?: boolean
  widestAvailablePackageSize: any
} & Pick<
  QueryResult<
    SubscriptionDataPackagesQuery,
    SubscriptionDataPackagesQueryVariables
  >,
  "data" | "loading"
>) => {
  const { t } = useAppTranslations()
  /*const xs = useBreakpoint("xs", { useMax: true, useMin: false })*/

  /*  const refresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await refetch()
    event.detail.complete()
  }*/
  const { mobileSubscription } = data || {}

  const potentialOfferingsEnabled = useFeatureIsOn("dbw-potential-offering")

  const dataPackages = new Array<
    AsDataPackageOfferingProductFragment & {
      priceDiff: number
      sizeType: keyof typeof sizeMultiplier
    }
  >()
  mobileSubscription?.customer?.agreements?.forEach((a) => {
    a?.offerings?.forEach((o) => {
      const subscriptionPriceInOffering = o.subscriptions?.length
        ? o.subscriptions[0].price
        : 0
      if (o?.dataProducts?.length) {
        if (
          !(
            featureFlags.allowChangeSubscriptionInDifferentOffering &&
            !data?.mobileSubscription?.customer?.cannotChangeOffering
          ) &&
          o.id !==
            data?.mobileSubscription?.customerAgreementOfferingId?.toString()
        ) {
          // Ignoring this package if feature is not enabled.
        } else {
          o.dataProducts.forEach((p) => {
            if (isPotentialOffering(p.id) && !potentialOfferingsEnabled) {
              //console.log("potential offerings not available yet")
            } else if (p.__typename !== "DataPackageOfferingProduct") {
              //console.log("not a DataPackageOfferingProduct", p)
            } else {
              if (`${o.id}-${p.productId}` === currentSubscriptionProductId) {
                //console.log("current product", p)
              } else {
                const dataPackage = p as AsDataPackageOfferingProductFragment
                const priceDiff =
                  dataPackage.price + subscriptionPriceInOffering - currentPrice
                dataPackages.push({
                  ...p,
                  name: `${o.name}`,
                  price: subscriptionPriceInOffering + dataPackage.price,
                  priceDiff: priceDiff,
                  sizeType:
                    (p.sizeType as keyof typeof sizeMultiplier) ??
                    sizeMultiplier.GB,
                })
              }
            }
          })
        }
      }
    })
  })

  return (
    <ProductList
      products={(sortDataProducts(dataPackages) as any) ?? []}
      placeholders={3}
      Component={(props) => (
        <DataPackage
          {...props}
          widestAvailablePackageSize={widestAvailablePackageSize}
        />
      )}
      animated={loading}
      accordian={(product) => (
        <PuxSheet
          color="medium"
          style={{
            textAlign: "center",
            paddingBottom: 28,
            paddingTop: 28,
            marginBottom: 12,
          }}
        >
          <strong>{t("Subscription.PerMonthExVat2")}</strong>
          <p>{t("Subscription.PerMonthIncludedDescription")}</p>
          <PuxButton
            style={{ minWidth: 250 }}
            color="primary"
            disabled={disabled}
            onClick={() => onSetSelected(product)}
          >
            Fortsett
          </PuxButton>
        </PuxSheet>
      )}
      onSelectProduct={onSetSelected}
      disabled={mobileSubscription?.userCannotChangeSubscription}
    />
  )
}
