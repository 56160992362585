import React, { lazy } from "react"
import css from "./PageContent.module.scss"

import {
  PuxContent,
  PuxFooter,
  PuxHeader,
  PuxPage,
  PuxRefresher,
  PuxRefresherContent,
} from "@phonero/pux-react"

import { DesktopHeader } from "./DesktopHeader"
// import { DesktopNavigation } from "./DesktopNavigation"
import { Footer } from "./Footer"
import PageHeader from "./PageHeader"
import { MaintenanceBanner, useBreakpointBelowTablet } from "@phonero/common-ux"

const DesktopNavigation = lazy(
  () =>
    import(/* webpackChunkName: 'DesktopNavigation' */ "./DesktopNavigation")
)

export type PageContentProps = Parameters<typeof PuxPage>[0] & {
  refresh?: () => void
  /**
   * If true: simulates a back-button
   * if string: very much like a href
   * if number: Goes "up" the url-hierarchi, for instance from '/foo/bar/baz' to '/foo/bar' if 1 is speciefied and '/foo/' if 2 is specified
   * */
  backLink?: boolean | string | number
  title?: string
}

export const PageContent = ({
  children,
  refresh,
  title,
  backLink,

  ...rest
}: PageContentProps) => {
  const onRefresh = async (event: CustomEvent) => {
    if (refresh) await Promise.resolve(refresh())
    event.detail.complete()
  }
  const isMobile = useBreakpointBelowTablet()
  const isDesktop = !isMobile

  return (
    <PuxPage className={css.wrapper} {...rest}>
      <PuxHeader>
        {/*Keeping this to make the page content go below the maintenance banner on top*/}
        <MaintenanceBanner hide={true} />
      </PuxHeader>
      <PuxHeader color="dark">
        {isMobile && <PageHeader title={title} backLink={backLink} />}
      </PuxHeader>
      <PuxContent fullscreen>
        {refresh && (
          <PuxRefresher slot="fixed" onPuxRefresh={onRefresh}>
            <PuxRefresherContent />
          </PuxRefresher>
        )}
        <DesktopHeader />
        {isDesktop && (
          <>
            <React.Suspense fallback="">
              <DesktopNavigation />
            </React.Suspense>
            <PageHeader title={title} backLink={backLink} />
          </>
        )}
        <div className={css.content}>{children}</div>
        <Footer />
      </PuxContent>
      <PuxFooter />
    </PuxPage>
  )
}
