import React, { useEffect } from "react"
import css from "./MaintenanceBanner.module.scss"
import { close } from "@phonero/pux-icons/icons"
import { PuxButton, PuxIcon, PuxItem } from "@phonero/pux-react"
import {
  ignoreMessagesBeforeDate,
  useIgnoreMessagesBeforeDate,
  useMaintenanceMessages,
} from "./MaintenanceMessageFetcher"

export const MaintenanceBanner = ({ hide }: { hide?: boolean }) => {
  const maintenanceMessages = useMaintenanceMessages()
  const { ignoreMessagesBefore, setIgnoreMessagesBefore } =
    useIgnoreMessagesBefore()

  const filteredMessages = maintenanceMessages?.filter(
    (message) =>
      !ignoreMessagesBefore ||
      new Date(message.created.timestampValue) > ignoreMessagesBefore
  )

  if (!filteredMessages?.length) {
    return null
  }
  return (
    <>
      <PuxItem
        className={css.maintenance}
        color={"primary"}
        style={{ zIndex: 100000, visibility: hide ? "hidden" : "visible" }}
      >
        <PuxButton
          className={css.bannerButton}
          onClick={setIgnoreMessagesBefore}
          slot={"end"}
        >
          <PuxIcon icon={close} />
        </PuxButton>
        <div className={css.bannerTextContainer}>
          {filteredMessages.map((message, index) => {
            return (
              <React.Fragment key={index}>
                {message?.summary?.stringValue}
                <br />
              </React.Fragment>
            )
          })}
        </div>
      </PuxItem>
    </>
  )
}

/**
 *   Testing custom hook for local storage access
 */
export const useIgnoreMessagesBefore = () => {
  const currentValue = useIgnoreMessagesBeforeDate()

  useEffect(() => {
    const dateFromStorage = localStorage.getItem("ignoreMessagesBefore")
    if (dateFromStorage) {
      ignoreMessagesBeforeDate(new Date(dateFromStorage))
    } else {
      ignoreMessagesBeforeDate(new Date(2000, 0, 1))
    }
  }, [])

  const setIgnoreMessagesBefore = () => {
    localStorage.setItem("ignoreMessagesBefore", new Date().toISOString())
    ignoreMessagesBeforeDate(new Date())
  }

  return { ignoreMessagesBefore: currentValue, setIgnoreMessagesBefore }
}
