import React, { Dispatch, FC, useState } from "react"
import {
  PuxFooter,
  PuxModalContent,
  PuxSheet,
  PuxSpinner,
} from "@phonero/pux-react"

import {
  ChangeDataPackageInput,
  DataPackageProduct,
  OfferingContractCategory,
  OrderStatusId,
  OrderType,
} from "@phonero/common-graphql/types"
import {
  graphqlQueryToast,
  useCurrentSubscriptionId,
  useAppTranslations,
  useAnalytics,
  subscriptionName,
  useGoBack,
  getWidestAvailablePackageSize,
  useAppQuery,
  OrdersDocument,
  OrdersQueryVariables,
  InlineError,
} from "@phonero/common-ux"
import { CheckoutApproval } from "./CheckoutApproval"
import { ChooseDataProduct, ShowActivationMessage } from "./ChooseDataproduct"
import DialogTitle from "../../../components/layout/DialogTitle"
import { FromProductWrapper } from "./FromProductWrapper"
import { ChangeSubscriptionCompleted } from "./ChangeSubscriptionCompleted"
import { OrderStatusInline } from "../../../pages/activity/dataMaps"
import {
  ApproversDocument,
  SubscriptionDataPackagesDocument,
} from "../../DataPackageQueries.generated"

export const ChangeSubscriptionContext = React.createContext<null | {
  selected?: Pick<DataPackageProduct, "productId"> & { offeringId: string }
  setSelected: Dispatch<any>
  input?: Partial<ChangeDataPackageInput>
  setInput: Dispatch<Partial<ChangeDataPackageInput>>
}>(null)

const analyticsProps = {
  orderKind: "change-subscription",
}

export const ordersVariables = (
  subscriptionId: string
): OrdersQueryVariables => ({
  first: 1,
  withDetails: true,
  where: {
    status: {
      nin: [OrderStatusId.Cancelled, OrderStatusId.Completed],
    },
    orderTypeId: {
      in: [OrderType.Changedatapackage],
    },
  },
  subscriptionID: subscriptionId,
})

export const ChangeSubscriptionDialog: FC = () => {
  const { subscriptionId } = useCurrentSubscriptionId()
  const { error: ordersError, ...orders } = useAppQuery(OrdersDocument, {
    skip: !subscriptionId,
    variables: ordersVariables(subscriptionId),
  })
  const { logPurchase, logOrderProcess, logOrderProcessCancellation } =
    useAnalytics()
  const [selected, setSelected] = useState<
    (DataPackageProduct & { subscriptionName?: string }) | null
  >(null)
  const [currentOrderId, setCurrentOrderId] = useState<string | null>(null)
  const { t } = useAppTranslations()
  const { goBack } = useGoBack({ onlyIfRouteMatch: "edit" })
  const onClose = () => {
    logOrderProcessCancellation()
    setSelected(null)
    setCurrentOrderId(null)
    goBack()
  }
  const {
    error: subscriptionPackagesError,
    data: subscriptionPackagesData,
    loading: subscriptionPackagesLoading,
  } = useAppQuery(SubscriptionDataPackagesDocument, {
    skip: !subscriptionId,
    variables: {
      offeringFilter: {
        priceChange: true,
        contractCategories: [OfferingContractCategory.Mobile],
      },
      subscriptionId,
      validForSubscription: subscriptionId,
    },
    fetchPolicy: "cache-and-network",
    ...graphqlQueryToast(t, "getDataPackages"),
  })

  const { error: approversError, ...approversData } = useAppQuery(
    ApproversDocument,
    {
      skip: !subscriptionId,
      variables: { subscriptionId: subscriptionId },
      fetchPolicy: "cache-and-network",
      ...graphqlQueryToast(t, "getApprovers"),
    }
  )

  function onSetSelected(p) {
    logOrderProcess({
      ...analyticsProps,
      stepName: "selected-product",
    })
    setSelected(p)
  }

  const firstDataPackage =
    subscriptionPackagesData?.mobileSubscription?.dataPackages?.[0]
  const firstDataPackageProductId =
    !!firstDataPackage &&
    "productId" in firstDataPackage &&
    firstDataPackage.productId

  const mobileSubscription = subscriptionPackagesData?.mobileSubscription
  const activeDataPackage = mobileSubscription?.dataPackages?.length
    ? mobileSubscription.dataPackages[0]
    : undefined
  const subscriptionProduct = mobileSubscription?.subscriptionProduct?.length
    ? mobileSubscription.subscriptionProduct[0]
    : undefined
  const activeDataPackagePrice =
    !!activeDataPackage && "price" in activeDataPackage
      ? activeDataPackage.price
      : null
  const subscriptionPackagePrice =
    !!subscriptionProduct && "price" in subscriptionProduct
      ? subscriptionProduct.price
      : null
  const currentPrice =
    (activeDataPackagePrice ?? 0) + (subscriptionPackagePrice ?? 0)

  const currentOfferingAndProductId = `${subscriptionPackagesData?.mobileSubscription?.customerAgreementOfferingId}-${firstDataPackageProductId}`
  const showLoading =
    (!subscriptionPackagesData && subscriptionPackagesLoading) ||
    (!orders.data && orders.loading)

  const availableProductList = new Array<any>()
  subscriptionPackagesData?.mobileSubscription?.customer?.agreements?.forEach(
    (a) => {
      a.offerings?.forEach((o) => {
        const subscriptionProduct = o.subscriptions?.length
          ? o.subscriptions[0]
          : { price: 0 }
        o?.dataProducts?.forEach((p) => {
          availableProductList.push({
            ...p,
            price: p.price + subscriptionProduct.price,
            name: `${o.name}`,
          })
        })
      })
    }
  )

  const { changeOrderApprovalRequired = false } =
    subscriptionPackagesData?.mobileSubscription || {}
  const previousActiveOrderIfAny =
    orders.data?.mobileSubscription?.orders?.nodes?.[0]
  const orderCount = orders.data?.mobileSubscription?.orders?.totalCount
  const alreadyOrdered =
    typeof orderCount === "number" && !currentOrderId && orderCount > 0
  const blocksBecauseAlreadyOrdered =
    alreadyOrdered && changeOrderApprovalRequired
  const orderedProduct =
    (!!previousActiveOrderIfAny?.requestedProductId &&
      (availableProductList?.find((p) => {
        if (
          String(p.id) === String(previousActiveOrderIfAny.requestedProductId)
        ) {
          return true
        }

        return false
      }) as unknown as DataPackageProduct)) ||
    null
  // FIXME: hack for name for product
  const orderedName = subscriptionName(
    subscriptionPackagesData?.mobileSubscription,
    { ...previousActiveOrderIfAny, ...orderedProduct }
  )

  const widestAvailablePackageSize =
    getWidestAvailablePackageSize(availableProductList)

  return (
    <>
      <DialogTitle
        onlyIfRouteMatch={["edit"]}
        {...(selected &&
          !currentOrderId && {
            onBackClick: () => {
              setSelected(null)
            },
          })}
      >
        Endre abonnement
      </DialogTitle>
      {showLoading && <PuxSpinner style={{ margin: "auto" }} />}
      <PuxModalContent>
        <InlineError error={ordersError} code="OrdersChangeSub" />
        <InlineError error={approversError} code="ApproversChangeSub" />
        <InlineError
          error={subscriptionPackagesError}
          code="subscriptionPackagesChangeSub"
        />
        {subscriptionPackagesData?.mobileSubscription &&
          !!currentOfferingAndProductId && (
            <>
              {/* Blocked if previously ordered: If query for orders on this subscription returns > 0 and the change subscription requires approval */}
              {!!blocksBecauseAlreadyOrdered && (
                <FromProductWrapper
                  loading={subscriptionPackagesLoading}
                  products={availableProductList}
                  currentProductPrice={currentPrice}
                  currentSubscriptionProductId={currentOfferingAndProductId}
                  widestAvailablePackageSize={widestAvailablePackageSize}
                  userCannotChangeSubscription={
                    subscriptionPackagesData?.mobileSubscription
                      ?.userCannotChangeSubscription
                  }
                >
                  <div style={{ textAlign: "center" }}>
                    {previousActiveOrderIfAny?.status !==
                      OrderStatusId.Cancelled &&
                      previousActiveOrderIfAny?.status !==
                        OrderStatusId.Completed && (
                        <p>Det finnes en aktiv endringsordre:</p>
                      )}
                    <p>
                      Ønsket bytte: <strong>{orderedName}</strong>
                    </p>
                    {previousActiveOrderIfAny?.status && (
                      <p>
                        Status:{" "}
                        <OrderStatusInline
                          status={previousActiveOrderIfAny.status}
                        />
                      </p>
                    )}
                    {previousActiveOrderIfAny?.status !==
                      OrderStatusId.Cancelled &&
                      previousActiveOrderIfAny?.status !==
                        OrderStatusId.Completed && (
                        <p>
                          Vennligst vent til denne ordren er ferdigbehandlet.
                        </p>
                      )}
                  </div>
                </FromProductWrapper>
              )}
              {/* If a new order can be placed */}
              {!blocksBecauseAlreadyOrdered && (
                <>
                  {/* When a new subscription is not selected... */}
                  {!selected ? (
                    <FromProductWrapper
                      loading={subscriptionPackagesLoading}
                      products={availableProductList}
                      currentSubscriptionProductId={currentOfferingAndProductId}
                      currentProductPrice={currentPrice}
                      widestAvailablePackageSize={widestAvailablePackageSize}
                      userCannotChangeSubscription={
                        subscriptionPackagesData?.mobileSubscription
                          ?.userCannotChangeSubscription
                      }
                    >
                      {previousActiveOrderIfAny && (
                        <ShowActivationMessage
                          lastActiveOrder={previousActiveOrderIfAny}
                          orderedName={orderedName}
                        />
                      )}
                      <ChooseDataProduct
                        currentPrice={currentPrice}
                        loading={subscriptionPackagesData?.loading}
                        data={subscriptionPackagesData}
                        widestAvailablePackageSize={widestAvailablePackageSize}
                        currentSubscriptionProductId={
                          currentOfferingAndProductId
                        }
                        onSetSelected={onSetSelected}
                      />
                    </FromProductWrapper>
                  ) : (
                    <>
                      {/* If a new subscription is selected and no order is placed */}
                      {!currentOrderId ? (
                        <CheckoutApproval
                          onSelectApprover={() => {
                            logOrderProcess({
                              ...analyticsProps,
                              stepName: "selected-approver",
                            })
                          }}
                          onOrderSent={(orderId: string) => {
                            setCurrentOrderId(orderId)
                            logOrderProcess({
                              ...analyticsProps,
                              stepName: "submit",
                            })
                          }}
                          {...approversData}
                          {...subscriptionPackagesData.mobileSubscription}
                          currentSubscriptionProductId={
                            firstDataPackageProductId
                          }
                          selected={selected}
                        />
                      ) : (
                        <ChangeSubscriptionCompleted
                          onClose={onClose}
                          subscriptionId={subscriptionId}
                          changeOrderApprovalRequired={
                            changeOrderApprovalRequired
                          }
                          orderId={currentOrderId}
                          onOrderStatusChange={(_prev, next) => {
                            logOrderProcess({
                              ...analyticsProps,
                              stepName: "status-" + next,
                            })
                            switch (next) {
                              case OrderStatusId.Completed:
                                logPurchase({
                                  id: selected.productId,
                                  name:
                                    selected.subscriptionName || selected.name,
                                  __typename: selected.__typename,
                                  price: selected.price,
                                })
                                subscriptionPackagesData?.refetch?.()
                                break

                              default:
                                break
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        {!currentOfferingAndProductId &&
          !subscriptionPackagesData?.loading === false && (
            <PuxSheet style={{ textAlign: "center", paddingBottom: 0 }}>
              {t("ChangeSubscription.ContactAdmin")}
            </PuxSheet>
          )}
      </PuxModalContent>
      <PuxFooter />
    </>
  )
}
