import React, { lazy, PropsWithChildren } from "react"
import { RightMenu } from "../../components/layout"
import { useBreakpointBelowTablet, useRouteMatch } from "@phonero/common-ux"
const MobileTabs = lazy(
  () => import(/* webpackChunkName: 'MobileTabs' */ "./MobileTabs")
)

export const SplitPaneLayout = ({ children }: PropsWithChildren<{}>) => {
  const { key: active } = useRouteMatch()
  const isMobile = useBreakpointBelowTablet()

  return (
    <>
      <RightMenu
        side="end"
        menuId="right"
        contentId="main"
        color="dark"
        type="push"
      />
      {isMobile && (
        <React.Suspense fallback="">
          <MobileTabs active={active} />
        </React.Suspense>
      )}

      {children}
    </>
  )
}
