/** Parses a period from a url in the format `yymm` or `yymm-yymm`.
 *
 * If the fir
 * */
export const parseShortPeriod = (s: string) => {
    if (!s) {
      return null
    }
    const [from, to] = s.split("-")
    const fromMonth = {
      year: Number("20" + from.slice(0, 2)),
      month: Number(from.slice(2)),
    }
    if (!to) {
      return { fromMonth, toMonth: fromMonth }
    }
    const toMonth = {
      year: Number("20" + to.slice(0, 2)),
      month: Number(to.slice(2)),
    }
    return { fromMonth, toMonth }
  }
  
  /** Formats a period for use in urls.
   * If only one date is supplied, it will return `yymm` (no leading zeroes)
   * If both parameters are supplied, it will return `yymm-yymm`.
   * However, if both parameters are equal, it will return `yymm`
   *
   * @see parseShortPeriod for parsing this format
   *
   * */
  export const formatPeriodForUrl = (from: Date, to?: Date) => {
    const first = formatShortMonthYear(from)
    if (!to) {
      return first
    }
    const second = formatShortMonthYear(to)
    if (first === second) {
      return first
    }
    return first + "-" + second
  }
  const formatShortMonthYear = (d: Date) => {
    const fd = new Date(d)
    const year = fd.getFullYear()
    const month = fd.getMonth() + 1
    return `${String(year).slice(2)}${month}`
  }
  