import React, { FC } from "react"
import { MobileInsuranceTermsLink } from "./MobileInsuranceTermsLink"
import { MobileInsuranceReportLink } from "./MobileInsuranceReportLink"

import css from "./MobileInsuranceLinks.module.scss"
import { MobileInsuranceCertificateLink } from "./MobileInsuranceCertificateLink"

export interface MobileInsuranceLinksProps {
  hasActiveProduct: boolean
  productId: number
  styleType: "button" | "anchor"
}

export const MobileInsuranceLinks: FC<MobileInsuranceLinksProps> = (
  props: MobileInsuranceLinksProps
) => {
  return (
    <div className={css.insuranceLinks}>
      <MobileInsuranceTermsLink productId={props.productId} styleType={props.styleType} />
      {props.hasActiveProduct && props.productId !== 10700 && (
        <>
          <MobileInsuranceReportLink productId={props.productId} styleType={props.styleType} />
          <MobileInsuranceCertificateLink productId={props.productId} styleType={props.styleType} />
        </>
      )}
    </div>
  )
}
