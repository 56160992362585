import { PredefinedColors } from "@phonero/pux-core"
import {
  purchase,
  abo,
  dataControl,
  close,
  request,
  approved,
  clock,
  chat,
  databoost,
  removeServices,
} from "@phonero/pux-icons/icons"
import { PuxText, PuxIcon } from "@phonero/pux-react"
import { OrderStatusId, OrderType } from "@phonero/common-graphql/types"
import { useAppTranslations } from "@phonero/common-ux"
export const orderStatusMap: Record<
  OrderStatusId,
  {
    color?: PredefinedColors
    icon: typeof request
    iconProps?: JSX.IntrinsicElements["span"]["style"]
  }
> = {
  // TODO: assign icons to these too
  [OrderStatusId.Cancelled]: {
    color: "danger",
    icon: close,
    iconProps: { transform: "scale(0.8)" },
  },
  [OrderStatusId.Completed]: {
    color: "success",
    icon: approved,
    iconProps: { transform: "translateY(1px)" },
  },
  [OrderStatusId.Registered]: { icon: request },
  [OrderStatusId.Pendingdelivery]: { color: "dark", icon: clock },
  [OrderStatusId.Pendingapproval]: { color: "dark", icon: chat },
}

// The icons below needs to be verified
export const orderTypeMap = {
  [OrderType.Changedatapackage]: { icon: request },
  [OrderType.Addbarringproduct]: { icon: abo },
  [OrderType.Addcostcontrol]: { icon: dataControl },
  [OrderType.Addextradatapackage]: { icon: purchase, iconOffset: 8 },
  [OrderType.Deactivateservice]: {
    icon: removeServices,
    iconOffset: 8,
  },
  [OrderType.Adddatarollover]: { icon: databoost },
  // TODO: assign icons to these too
} as const

export function OrderStatusInline({
  status,
  noIcon,
  color,
  ...rest
}: {
  noIcon?: boolean
  status: OrderStatusId
  color?: PredefinedColors
} & Omit<JSX.IntrinsicElements["span"], "ref" | "color">) {
  const { t } = useAppTranslations()
  const b = orderStatusMap[status]
  const Container = color ? PuxText : "span"
  return (
    <Container color={b.color} {...rest}>
      {!noIcon && (
        <PuxIcon
          size="small"
          icon={b.icon}
          style={{ marginRight: 4, verticalAlign: "top" }}
        />
      )}
      {t("orders.status", { context: status })}
    </Container>
  )
}
